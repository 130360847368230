import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// UX Research, Figma, React, API proxying
import { ReactComponent as DesignIcon } from './svg/design.svg';
import { ReactComponent as ManagementIcon } from './svg/package.svg';
import { ReactComponent as LinearIcon } from './svg/linear.svg';
import { ReactComponent as FigmaIcon } from './svg/figma.svg';
import { ReactComponent as VueIcon } from './svg/vue.svg';
import { ReactComponent as MetabaseIcon } from './svg/metabase.svg';
import { ReactComponent as MixpanelIcon } from './svg/mixpanel.svg';
import { ReactComponent as TailwindIcon } from './svg/tailwind.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
import ReferenceLink from '@common/ReferenceLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './reach-and-activation.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/reach-and-activation/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/reach-and-activation/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Paragraph>
                <ReferenceLink href="https://www.birdeatsbug.com/">
                  Check it out
                </ReferenceLink>
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="Searcher redesigned"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <DesignIcon />
                  <LearningTopic>Product Design</LearningTopic>
                </Learning>
                <Learning>
                  <ManagementIcon />
                  <LearningTopic>Product Management</LearningTopic>
                </Learning>
                <Learning>
                  <FigmaIcon />
                  <LearningTopic>Figma</LearningTopic>
                </Learning>
                <Learning>
                  <VueIcon />
                  <LearningTopic>Vue</LearningTopic>
                </Learning>
                <Learning>
                  <TailwindIcon />
                  <LearningTopic>TailwindCSS</LearningTopic>
                </Learning>
                <Learning>
                  <LinearIcon />
                  <LearningTopic>Linear</LearningTopic>
                </Learning>
                <Learning>
                  <MixpanelIcon />
                  <LearningTopic>Mixpanel</LearningTopic>
                </Learning>
                <Learning>
                  <MetabaseIcon />
                  <LearningTopic>Metabase</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Overview</Title>

              <List>
                <Item>
                  Simplified a feature, redesigned to make it more prominent and
                  added onboarding cues and a feedback loop. More users got to
                  try the feature (1.5X) and kept using it afterwards (2X).
                </Item>
                <Item>
                  Challenged the current activation metric and proposed a new
                  one that better aligned with paid customers. Designed changes
                  to promote relevant actions. More teams exhibited a desired
                  behaviour (1.4X) and enabled a key activation feature (4X).
                </Item>
              </List>

              <Title>Understanding why a feature isn't used</Title>

              <Paragraph>
                Product data showed that a feature, which was expected to be an
                interesting addition, had a very small adoption and never really
                took off.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="features"
                overflow
                alt="Usage of features"
                description="Breakdown of the usage of complementary features, in blue the one under study"
              />

              <Paragraph>
                After more than 30h of user research interviews conducted
                reasoning was emerging on, among others, why the adoption of the
                feature wasn't as we expected.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="user-research"
                overflow
                alt="Insights from user research"
                description="Example extracts about the feature documented across user research interviews"
              />

              <Paragraph>
                The consensus was that it was hard to start using it...
              </Paragraph>
              <List>
                <Item>
                  As it requires that the user first enables one component on a
                  given domain
                </Item>
                <Item>
                  But it wasn't clearly stated at the browser extension that the
                  component is opt-in by domain
                </Item>
                <Item>
                  And the relationship between both components was also not
                  clearly communicated
                </Item>
              </List>

              <Paragraph>
                ... and then really easy to dismiss and forget about it:
              </Paragraph>
              <List>
                <Item>
                  Closing the browser extension widget (a shortcut to access the
                  feature) disabled it entirely
                </Item>
                <Item>
                  Re-enabling it meant fiddling with the extension settings
                </Item>
                <Item>
                  Which left the user with only one way to access the feature:
                  through the browser extension popup (where other competing
                  features are located)
                </Item>
              </List>

              <Paragraph>
                Moreover, one component of the feature was domain-based (which
                was praised by users), but the others were global. It was too
                easy to end up by mistake with just half of the feature enabled,
                which rendered it useless.
              </Paragraph>

              <Title>Designing for feature discovery</Title>

              <Paragraph>
                Work started with the following goals in mind:
              </Paragraph>
              <List>
                <Item>
                  Simplifying the feature by packaging everything together
                </Item>
                <Item>Giving more prominence to the feature</Item>
                <Item>Adding onboarding flows</Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="settings-locations"
                overflow
                alt="Settings exploration"
                description="Layout exploration on the location of settings, to clear the layout"
              />

              <Image
                edges={data.designs.edges}
                name="exploration-1"
                overflow
                alt="Early exploratory designs"
                description="Early designs on the feature toggle and interactions with quick settings"
              />

              <Image
                edges={data.designs.edges}
                name="explorations"
                overflow
                alt="Early exploratory designs"
                description="Overview of an exploration page in Figma"
              />

              <Image
                edges={data.designs.edges}
                name="exploration-2"
                overflow
                alt="Early exploratory designs"
                description="Discarded designs on hiding settings behind a dropdown"
              />

              <Image
                edges={data.designs.edges}
                name="exploration-3"
                overflow
                alt="Early exploratory designs"
                description="Close to final designs of the main states, with the feature on and off"
              />

              <Image
                edges={data.designs.edges}
                name="exploration-cues"
                overflow
                alt="Early exploratory designs"
                description="Onboarding cue and feedback loop"
              />

              <Image
                edges={data.designs.edges}
                name="final-flow"
                overflow
                alt="Early exploratory designs"
                description="Final specs ready for handover to engineering showing the complete flow"
              />

              <Title>Results: 2X more feature discovery </Title>

              <List>
                <Item>
                  New signups now discover the feature 1.5X more than before
                  (13% vs 8%)
                </Item>
                <Item>
                  Users who try the feature keep using it 2X more than before
                  (30% vs 15%)
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="discovery"
                overflow
                alt="Increase in discovery"
                description="Trial of the feature within 7 days from signup"
              />

              <Image
                edges={data.designs.edges}
                name="retention"
                overflow
                alt="Increase in retention"
                description="Repeated usage within 7 days of first use, May vs October"
              />

              <Title>Challenging the activation rate</Title>

              <Image
                edges={data.designs.edges}
                name="activation"
                overflow
                alt="Weekly activation rate"
                description="Weekly activation rate"
              />

              <Paragraph>
                Despite seeing an apparently healthy activation rate of close to
                40%, we weren't satisfied with either the usage nor the
                conversion we were seeing. So we went digging.
              </Paragraph>

              <Title>Defining a new metric</Title>

              <Paragraph>
                Analyzing what early tells a workspace that eventually was to
                become a paid plan customer had, we discarded many actions and
                features we would have (based on intuition) expected to be clear
                indicators.
              </Paragraph>
              <Paragraph>But we found that during those first days:</Paragraph>
              <List>
                <Item>
                  They exhibited a first type of action 4X more than the rest
                </Item>
                <Item>
                  They exhibited a second type of action 6X more than the rest
                </Item>
                <Item>
                  There was a 60% likelihood they had enabled a specific feature
                </Item>
              </List>

              <Paragraph>
                We decided to use them as the defining criteria of a new
                activation metric and set out to try find the optimum values
                using common sense and past data:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="quantification"
                overflow
                alt="Quantification of criteria"
                description="Breakdown of usage by how many times one type of action is performed early"
              />

              <Paragraph>
                Once we narrowed down the best values for each criteria, we
                tried validating these against past data we have collected since
                we launched. We saw that:
              </Paragraph>
              <List>
                <Item>
                  Workspaces that met the 1st activation criteria displayed 4X
                  more usage by month 6
                </Item>
                <Item>
                  Workspaces that met the 2nd activation criteria displayed 7X
                  more usage by month 6
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="uploads-usage"
                overflow
                alt="Usage based on 1st criteria"
                description="Difference in usage over the first 6 months, 1st criteria"
              />

              <Image
                edges={data.designs.edges}
                name="invites-usage"
                overflow
                alt="Usage based on 2nd criteria"
                description="Difference in usage over the first 6 months, 2nd criteria"
              />

              <Paragraph>
                From the perspective of retention, comparing the proposed
                activation metric against the existing one we saw that:
              </Paragraph>

              <List>
                <Item>
                  32% of activated workspaces don't survive past Month 1 (down
                  from 49%)
                </Item>
                <Item>
                  40% of activated workspaces are still active by Month 6 (up
                  from 22%)
                </Item>
              </List>

              <Paragraph>
                After sharing all this research with the team, we agreed to
                experiment with the new activation rate metric and introduce
                changes to the app to promote those behaviours.
              </Paragraph>

              <Title>Designing for feature opt-in</Title>

              <Paragraph>
                Once we were all aligned, design work started on a wide array of
                feature improvements and fixes to address the new metric.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="modular-component"
                overflow
                alt="Modular component design"
                description="Designs for a modular component to introduce a behaviour in multiple locations"
              />

              <Image
                edges={data.designs.edges}
                name="applied-component"
                overflow
                alt="Modular component applied"
                description="The component applied"
              />

              <Image
                edges={data.designs.edges}
                name="designs"
                overflow
                alt="Design file"
                description="Overview of a design file ready to be handed over"
              />

              <Image
                edges={data.designs.edges}
                name="invite-screen"
                overflow
                alt="Invite screen designs"
                description="Exploration of different variants to promote a desired behaviour on a screen"
              />

              <Title>Results: 4X more opt-in to a key feature</Title>

              <List>
                <Item>
                  New workspaces now exhibit one desired behaviour during the
                  first days 1.4X more than before (8% vs 19%)
                </Item>
                <Item>
                  A key activation feature is being enabled 4X more than before,
                  reaching again all-time high ratios
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="early-invites"
                overflow
                alt="Workspaces exhibiting desired behaviour"
                description="Workspaces exhibiting one of the targeted behaviours early on"
              />

              <Image
                edges={data.designs.edges}
                name="autojoin"
                overflow
                alt="Workspaces enabling a key feature"
                description="Workspaces enabling a key feature within the first 7 days (which had dropped significantly)"
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
